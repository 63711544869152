<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import Calendar from 'primevue/calendar';

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
    Calendar,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      statData: [
        { type: "line", title: "Total de Vendas", value: "R$ -,--", icon: "bx-dollar-circle", },
        { type: "line", title: "Total à Liberar", value: "R$ -,--", icon: "bx-dollar-circle", },
        { type: "line", title: "Total Liberado", value: "R$ -,--", icon: "bx-dollar-circle", },
      ],

      search: "",

      total: 0,

      fields: [
        { key: "id", label: "Pedido" },
        { key: "client", label: "Cliente" },
        { key: "products", label: "Produtos" },
        { key: "shipping", label: "Frete" },
        { key: "total", label: "Total" },
        { key: "cost", label: "Custo" },
        { key: "nfe", label: "NF-e" },
        { key: "release", label: "À receber" },
        { key: "status", label: "Status" },
      ],
      items: null,

      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "25",
      period: [
        new Date(new Date().setDate(new Date().getDate() - 180)),
        new Date(),
      ],
    };
  },
  methods: {
    getSales() {
      this.items = null;

      api
        .get("reports/sales", {
          search: this.search,
          period: this.period.length === 2 ? this.period.map(date => date.toISOString().split('T')[0]).join(',') : '',
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.total = response.data.total;
            this.items = response.data.list;

            this.statData = [
              { type: "line", title: "Total de Vendas", value: this.$options.filters.currency(this.total.sales).toString(), icon: "bx-dollar-circle", },
              { type: "line", title: "Total à Liberar", value: this.$options.filters.currency(this.total.to_release).toString(), icon: "bx-dollar-circle", },
              { type: "line", title: "Total Liberado", value: this.$options.filters.currency(this.total.released).toString(), icon: "bx-dollar-circle", },
            ];
          } else {
            this.total = 0;
            this.items = [];
          }
        })
        .catch((error) => {
          if (error) {
            this.total = 0;
            this.items = [];
          }
        })
    },
    filterSearch() {
      this.getSales();
    }
  },
  mounted() {
    this.getSales();
  },
  watch: {
    period(dates) {
      this.period = dates;

      if (dates[0] && dates[1]) {
        this.getSales();
      }
    },
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Relatórios</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Vendas realizadas</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <b-form @submit.prevent="filterSearch">
      <b-input-group class="search mb-3">
        <b-form-input v-model="search" placeholder="Pedidos, clientes ou código de rastreio" autocomplete="off"></b-form-input>
        <b-input-group-append>
          <b-button type="submit" variant="default">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
              <path
                d="M19.023 16.977a35.13 35.13 0 0 1-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0 0 16 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z">
              </path>
            </svg>
            Buscar
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>

    <div id="dropdown" class="d-flex mb-4">
      <b-dropdown variant="outline-light" class="period" ref="period">
        <template #button-content>
          período
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <Calendar v-model="period" inline selectionMode="range" dateFormat="dd/mm/yy" :manualInput="false" hide-header></Calendar>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
    </div>

    <div v-if="!items" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div class="card" v-else-if="items && items.length == 0">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum pedido encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover table-pointer min-vh-25" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" responsive>
            <template #cell(products)="row">
              {{ row.item.products | currency }}
            </template>
            <template #cell(shipping)="row">
              {{ row.item.shipping | currency }}
            </template>
            <template #cell(total)="row">
              {{ row.item.total | currency }}
            </template>
            <template #cell(cost)="row">
              {{ row.item.cost | currency }}
            </template>
            <template #cell(nfe)="row">
              {{ row.item.nfe | currency }}
            </template>
            <template #cell(release)="row">
              {{ row.item.release | currency }}
            </template>
            <template #cell(status)="row">
              <span class="badge badge-status bg-soft-warning" v-if="row.item.status == 'to_release'">À Liberar</span>
              <span class="badge badge-status bg-soft-success" v-else-if="row.item.status == 'released'">Liberado</span>
              <span class="badge badge-status bg-soft-danger" v-else-if="row.item.status == 'blocked'">Bloqueado</span>
              <span class="badge badge-status bg-soft-danger" v-else-if="row.item.status == 'canceled'">Cancelado</span>
            </template>
          </b-table>
        </div>

        <b-pagination class="mt-3" v-model="currentPage" :total-rows="total.orders" :per-page="perPage" pills align="center"></b-pagination>
      </div>
    </div>
  </Layout>
</template>